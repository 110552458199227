import React from 'react'
import {YMInitializer} from 'react-yandex-metrika';

export default () => {

    return (
        <YMInitializer accounts={[64804579]}
                       options={{
                           clickmap: true,
                           trackLinks: true,
                           accurateTrackBounce: true,
                           webvisor: true,
                       }}

        />
    )
}

