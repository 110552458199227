//actions
export const FETCH_SUPPORT_START = 'FETCH_SUPPORT_START'
export const FETCH_SUPPORT_SUCCESS = 'FETCH_NAV_SUCCESS'
export const FETCH_SUPPORT_ERROR = 'FETCH_SUPPORT_ERROR'

//feedback

export const SET_FEEDBACK_PROPERTY = 'SET_FEEDBACK_PROPERTY'
export const SET_FORM_CONTROL_PROPERTY = 'SET_FORM_CONTROL_PROPERTY'
export const SHOW_FEEDBACK = 'SHOW_FEEDBACK'
export const FETCH_SEND_FORM_START = 'FETCH_SEND_FORM_START'
export const FETCH_SEND_FORM_SUCCESS = 'FETCH_SEND_FORM_SUCCESS'
export const FETCH_SEND_FORM_ERROR = 'FETCH_SEND_FORM_ERROR'


export const SET_REF = 'SET_REF'