import React, {Fragment, Suspense, lazy} from 'react';

import './App.css';
import './App.scss'
import Loader from "./components/UI/Loader/Loader"
import {Redirect} from "react-router-dom";
import YandexMetrika from "./containers/YandexMetrika/YandexMetrika";
// import YandexMetrika from "./containers/YandexMetrika/YandexMetrika";



// import {withRouter, Route} from 'react-router-dom'
// import MainPage from "./containers/MainPage/MainPage";
// import {SectionsContainer, Section} from 'react-fullpage';

const Header = lazy(() => import( "./containers/Header/Header"))
const HomeSlider = lazy(() => import( "./containers/HomeSlider/HomeSlider"))
const Footer = lazy(() => import( "./containers/Footer/Footer"))
const About = lazy(() => import( "./containers/About/About"))
const Services = lazy(() => import( "./containers/Services/Services"))
const Partners = lazy(() => import( "./containers/Partners/Partners"))
const WeBuilt = lazy(() => import( "./containers/WeBuilt/WeBuilt"))
const WeDesign = lazy(() => import( "./containers/WeDesign/WeDesign"))
const Stock = lazy(() => import( "./containers/Stock/Stock"))
const Sale = lazy(() => import('./containers/Sale/Sale'))
const Reviews = lazy(() => import('./containers/Reviews/Reviews'))
const YandexMap = lazy(() => import("./containers/YandexMap/YandexMap"));

function App() {

    return (
        <Fragment>
            <YandexMetrika/>
            <Suspense fallback={<Loader/>}>
                <Header/>
                <HomeSlider/>
                <About/>
                <WeBuilt/>
                <WeDesign/>
                <Stock/>
                <Sale/>
                <Services/>
                <Partners/>
                <Reviews/>
                <YandexMap/>
                <Footer/>
                {/*<Redirect to={"/"}/>*/}
            </Suspense>
        </Fragment>
    );
}

export default App;
