import {SET_FEEDBACK_PROPERTY, SET_FORM_CONTROL_PROPERTY} from "../actions/Actions";

const initialState = {
    showPopup: false,
    showAnswer: false,
    formValid: false,
    formControls: {
        name: {
            id: 'name',
            label: 'Моё имя',
            type: 'text',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить'
            },
        },
        phone: {
            id: 'phone',
            label: 'Мой телефон',
            type: 'phone',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
                phone: true
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                phone: `Пожалуйста введите корректный номер`
            },
        },
    },
}
export default function supportReducer(state = initialState, action) {

    // console.log(action)

    switch (action.type) {
        case SET_FORM_CONTROL_PROPERTY:


            let formControls = {...state.formControls}
            formControls[action.property].value = action.value
            formControls[action.property].error = action.error
            formControls[action.property].valid = !action.error

            let formValid = true
            let keys = Object.keys(formControls)

            keys.forEach(key => {
                formValid = formValid && formControls[key]['valid']

            })


            return {
                ...state,
                formControls,
                formValid
            }
        case SET_FEEDBACK_PROPERTY:

            return {
                ...state,
                [action.property]: action.value,
            }
        default:
            return state
    }
}