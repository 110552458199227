import {
    FETCH_SUPPORT_ERROR,
    FETCH_SUPPORT_START,
    FETCH_SUPPORT_SUCCESS,
    SET_REF
} from "../actions/Actions";

const initialState = {
    // nav: [
    //     {
    //         "id": 1,
    //         "name": "О нас",
    //         "link": "about",
    //     },
    //     {
    //         "id": 2,
    //         "name": "Построили",
    //         "link": "built",
    //     },
    //     {
    //         "id": 3,
    //         "name": "Проектируем",
    //         "link": "design",
    //     },
    //     {
    //         "id": 4,
    //         "name": "Продаём",
    //         "link": "sale",
    //     },
    //     {
    //         "id": 5,
    //         "name": "Отзывы",
    //         "link": "reviews",
    //     },
    //     {
    //         "id": 6,
    //         "name": "Контакты",
    //         "link": "contacts",
    //     },
    // ],
    nav: [],
    headings: {},
    links:[],
    contacts: {},
    social: {},
    mainSlider: [],
    about: [],
    weBuilt: [],
    weDesign: [],
    stocks: [],
    sale: [],
    partners:[],
    reviews:[],
    services:[],
    mapData: {},
    feedback: {},
    refs: [],
    // slider:[],
    loading: false,
    error: false
}
export default function supportReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SUPPORT_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_SUPPORT_SUCCESS:
            return {
                ...state,
                nav: action.support.nav,
                headings: action.support.headings,
                links: action.support.links,
                contacts: action.support.contacts,
                social: action.support.social,
                mainSlider: action.support.mainSlider,
                about: action.support.about,
                weBuilt: action.support.weBuilt,
                weDesign: action.support.weDesign,
                stocks: action.support.stocks,
                sale: action.support.sale,
                partners: action.support.partners,
                reviews: action.support.reviews,
                services: action.support.services,
                mapData: action.support.mapData,
                feedback: action.support.feedback,
                loading: false
            }
        case FETCH_SUPPORT_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case SET_REF:
            let refs = [...state.refs]
            refs.push({
                id: action.id,
                ref: action.ref
            })
            return {
                ...state,
                refs
            }
        default:
            return state
    }
}